import React, { useEffect } from "react";
import "./Home.scss";
import AOS from "aos";
import "aos/dist/aos.css";

const Home = () => {

    const link = "https://www.booking.com/hotel/lk/heaven-palace.html?aid=318615&label=New_English_EN_LK_27027142825-OOHRKEm2e9cX4E5_mJSvaAS640874803405%3Apl%3Ata%3Ap1%3Ap2%3Aac%3Aap%3Aneg%3Afi%3Atidsa-199482273865%3Alp9076000%3Ali%3Adec%3Adm%3Aag27027142825%3Acmp400679785&sid=d12f6e9c8e4dd7daa75a5b567374e10d&all_sr_blocks=1139697102_386853364_2_2_0%2C1139697105_386853364_2_2_0;checkin=2024-10-09;checkout=2024-10-10;dest_id=-2222251;dest_type=city;dist=0;group_adults=4;group_children=0;hapos=1;highlighted_blocks=1139697102_386853364_2_2_0%2C1139697105_386853364_2_2_0;hpos=1;matching_block_id=1139697102_386853364_2_2_0;no_rooms=1;req_adults=4;req_children=0;room1=A%2CA%2CA%2CA;sb_price_type=total;sr_order=popularity;sr_pri_blocks=1139697102_386853364_2_2_0__4500%2C1139697105_386853364_2_2_0__6000;srepoch=1727932666;srpvid=f8b0253cf5f901fa;type=total;ucfs=1&";
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
    });
  }, []);
  return (
    <section className="home">
      <div className="secContainer container">
        <div className="homeText" data-aos="fade-down">
          <h1 className="title">Plan Your Trip With Heaven Palace</h1>

          <p className="subtitle">
            Travel to your favourite city with respectful of the environment
          </p>

          <button className="btn">
            <a href="#">Explore Now...</a>
          </button>
        </div>

        <div className="homeCard" data-aos="fade-down">
          <div className="locationDiv">
            <label htmlFor="location">Your Appoinment</label>
            <input type="text" placeholder='+94 777 158 754' />
          </div>

          <div className="disDiv">
            <label htmlFor="distance">Visit Us</label>
            <input type="text" placeholder='www.fb.com/heavenpalace' />
          </div>

          <div className="priceDiv">
            <label htmlFor="price">Conact Via</label>
            <input type="text" placeholder='heavenpalacepvt@gmail.com' />

          </div>
          <button
            className="btn"
            onClick={() => {
                window.open(link, '_blank');
            }}
          >
            Book Now
          </button>
        </div>
      </div>
    </section>
  );
};

export default Home;
