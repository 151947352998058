import React from "react";
import "./Footer.scss";
import { SlPlane } from "react-icons/sl";
import { ImFacebook } from "react-icons/im";
import { TbBrandBooking } from "react-icons/tb";
import { AiFillInstagram } from "react-icons/ai";
import logo from '../../Assets/logo_black.png'

const Footer = () => {
  return (
    <div className="footer">
      <div className="secContainer conatiner grid">
        <div className="logoDiv">
          <div className="footerLogo">
            <a href="" className="logo">
            <img src={logo} alt="" />
              {/* <SlPlane className="icon" /> */}
              <h1 className="logoName">Heaven Palace</h1>
            </a>
          </div>
          <div className="socilas flex">
            <ImFacebook className="icon" />
            <TbBrandBooking className="icon" />
            <AiFillInstagram className="icon" />
          </div>
        </div>
      

      <div className="footerLinks">
        <span className="linkTitle">Information</span>
        <li>
          <a href="">Home</a>
        </li>

        <li>
          <a href="">Destinations</a>
        </li>

        <li>
          <a href=""> Offers</a>
        </li>

        <li>
          <a href="">Why</a>
        </li>
      </div>

      <div className="footerLinks">
        <span className="linkTitle">Helpful Links</span>
        <li>
          <a href="">Destination</a>
        </li>

        <li>
          <a href="">Support</a>
        </li>

        <li>
          <a href=""> Travel & Conditions</a>
        </li>

        <li>
          <a href="">Privacy</a>
        </li>
      </div>

      <div className="footerLinks">
        <span className="linkTitle">Contact Us</span>
        <span className="phone">+94 777 158 754</span>
        <span className="email">www.fb.com/heavenpalace</span>
      </div>
    </div>
    </div>
  );
};

export default Footer;
