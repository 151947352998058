import React, { useState, useEffect } from 'react';
import './Navbar.scss';
import { AiFillCloseCircle } from 'react-icons/ai';
import { TbGridDots } from 'react-icons/tb';
import logo from '../../Assets/logo_white.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-scroll';

const Navbar = () => {
  const [navActive, setNavActive] = useState(false);

  const [isScrolled, setIsScrolled] = useState(true);

  const toggleNav = () => {
    setNavActive(!navActive);
  };

  const handleScroll = () => {
    if (window.scrollY >= 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    AOS.init({
      duration: 1000, 
      easing: 'ease-in-out', 
      once: true, 
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className="navBarSection">
      <div className={isScrolled ? 'header activeHeader' : 'header'} data-aos="fade-down">
        <div className="logoDiv">
          <Link to="/" smooth={true} duration={500} className="logo" onClick={() => setNavActive(false)}>
            <img src={logo} alt="Heaven Palace Logo" />
          </Link>
        </div>

        <div className={navActive ? 'navBar activeNavbar' : 'navBar'}>
          <ul className="navList flex" data-aos="fade-down">
            <li className="navItem">
              <Link to="/" smooth={true} duration={500} className="navLink" onClick={() => setNavActive(false)}>
                Home
              </Link>
            </li>

            <li className="navItem">
              <Link to="popular" smooth={true} duration={500} className="navLink" onClick={() => setNavActive(false)}>
                Destinations
              </Link>
            </li>

            <li className="navItem">
              <Link to="offer" smooth={true} duration={500} className="navLink" onClick={() => setNavActive(false)}>
                Offers
              </Link>
            </li>

            <li className="navItem">
              <Link to="about" smooth={true} duration={500} className="navLink" onClick={() => setNavActive(false)}>
                Why
              </Link>
            </li>

            <li className="navItem">
              <Link to="gallery" smooth={true} duration={500} className="navLink" onClick={() => setNavActive(false)}>
                Gallery
              </Link>
            </li>

            <div className="headerBtns flex" data-aos="fade-down">
              <button className="btn loginBtn">
                <Link to="login" smooth={true} duration={500} onClick={() => setNavActive(false)}>
                  Login
                </Link>
              </button>
              <button className="btn">
                <Link to="signup" smooth={true} duration={500} onClick={() => setNavActive(false)}>
                  Sign Up
                </Link>
              </button>
            </div>
          </ul>

          <div onClick={toggleNav} className="closeNavBar">
            <AiFillCloseCircle className="icon" />
          </div>
        </div>

        <div onClick={toggleNav} className="toggleNavBar">
          <TbGridDots className="icon" />
        </div>
      </div>
    </section>
  );
};

export default Navbar;
