import React, { useState, useEffect } from 'react';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import img1 from '../../Assets/img1.jpg';
import img2 from '../../Assets/img18.jpg';
import img3 from '../../Assets/img10.jpg';
import img4 from '../../Assets/img14.jpg';
import img5 from '../../Assets/img15.jpg';
import img6 from '../../Assets/img16.jpg';
import img7 from '../../Assets/img17.jpg';
import img8 from '../../Assets/img20.jpg';
import AOS from "aos";
import "aos/dist/aos.css";

const images = [img1, img2, img3, img4, img5, img6, img7, img8];

const Gallery = () => {
  const [data, setData] = useState({ img: '', i: 0 });

  const viewImage = (img, i) => {
    setData({ img, i });
  };

  const imgAction = () => {
    setData({ img: '', i: 0 });
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <div className="gallery-container" id="gallery">
        {data.img && (
          <div style={{
            width: '100%',
            height: '100vh',
            background: 'black',
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            top: 0,
            left: 0,
            zIndex: 9999
          }}>
            <button onClick={() => imgAction()} style={{
                position: 'absolute',
                top: '5%',
                right: '5%',
                backgroundColor: 'hsl(26,93%,50%)', 
                color: 'hsl(0,0%,100%)', 
                border: 'none',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '24px',
                cursor: 'pointer',
                transition: 'background-color 0.3s, transform 0.3s',
                zIndex: 1000
              }}>X</button>
            <img src={data.img} style={{ width: 'auto', maxWidth: '90%', maxHeight: '90%' }} />
          </div>
        )}
        
        <div className="secContainer" data-aos="zoom-in">
          <h1 className="title" style={{ textAlign: 'center', margin: '10px 0' }}>Captured Moments</h1>
          <p style={{ textAlign: 'center', margin: '10px 0' }}>
            Explore the beauty and tranquility of our stunning surroundings captured in these images.
          </p>
        </div>

        <div style={{ padding: '0px' }}>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
            <Masonry gutter="20px">
              {images.map((image, i) => (
                <div className="image-container" key={i}>
                  <img
                    src={image}
                    style={{ width: "100%", display: "block" }}
                    alt=""
                    onClick={() => viewImage(image, i)}
                    data-aos="zoom-in" />
                </div>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </div>
    </>
  );
};

export default Gallery;
