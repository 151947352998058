import React from "react";
import { useParams } from "react-router-dom";
import "./OfferDetail.scss";
import { Offers } from "./Offer"; // Import Offers array
import Navbar from "../../Components/Navbar2/Navbar";
import { MdBathtub, MdAirportShuttle, MdDone, MdWifi, MdLocalParking  } from "react-icons/md";
import { BsArrowRightShort } from "react-icons/bs";
import { FaWifi } from "react-icons/fa";
import { IoMdPricetags } from "react-icons/io";
import { FaBed } from "react-icons/fa6";
import { ImSpoonKnife } from "react-icons/im";
import { TbAirConditioning } from "react-icons/tb";
import { FaBath, FaTree, FaUtensils } from "react-icons/fa";

const iconMapping = {
  "1 Bed": <FaBed className="icon" />,
  Beds: <FaBed className="icon" />,
  "Private Kitchen": <ImSpoonKnife className="icon" />,
  "Private Kitchens": <ImSpoonKnife className="icon" />,
  "Ensuite Bathroom": <MdBathtub className="icon" />,
  "Ensuite Bathrooms": <MdBathtub className="icon" />,
  "Air Conditioning": <TbAirConditioning className="icon" />,
  Shower: <MdBathtub className="icon" />,
  Bidet: <MdDone className="icon" />,
  Refrigerator: <MdDone className="icon" />,
  Fan: <MdDone className="icon" />,
  "Dining Area": <MdDone className="icon" />,
  "Dining Table": <MdDone className="icon" />,
  "Private Bathroom": <FaBath className="icon" />,
  "Free WiFi": <FaWifi className="icon" />,
};

const OfferDetail = () => {
  const { id } = useParams();
  const offer = Offers.find((item) => item.id === parseInt(id));

  if (!offer) {
    return <div className="not-found">Offer not found.</div>;
  }

  return (
    <div>
      <section>
        <Navbar />
      </section>
      <div className="offer-detail">
        <div className="offer-content">
          <div className="offer-header">
            <h1>
              <span>Special Offers</span>
            </h1>
            <h2>{offer.desTitle}</h2>
            <p>
              We have a wide range of Hotel Rooms, Resorts, and villas with
              exciting deals and offers specially for our valuable customers.
            </p>
          </div>
          {/* Existing Offer Details */}
          <div className="offer-details">
            <img src={offer.imgSrc} alt={offer.desTitle} />
            <div className="offer-info">
              <h2>{offer.desTitle}</h2>
              {/* <p className="price">{offer.grade}/night</p> */}
              {/* <button className="view-btn">View</button> */}
              <ul>
                {[offer.option1, offer.option2, offer.option3, offer.option4].map(
                  (option) => (
                    <li key={option} className="amenity">
                      {iconMapping[option]} <span>{option}</span>
                    </li>
                  )
                )}
              </ul>
              <ul >
              {<MdDone className="icons" />}
              <span className="">{offer.breakfast}</span>
              <br />
              {<MdDone className="icons" />}
              <span className="">{offer.credit}</span>
              <br />
              {<MdDone className="icons" />}
              <span className="">{offer.cancel}</span>
              <br />
              {<MdDone className="icons" />}
              <span className="">{offer.prepayment}</span>
              </ul>
            </div>
          </div>
          {/* New Facilities Section */}
          <div className="facilities-section">
            <h2>Facilities of Heaven Palace</h2>


            <div className="contentend">
          <div className="firstRow" >
          Most popular facilities
          </div>
          <div className="secondRow" >
            <span className="features">
              <span className="aa">{<MdDone className="icon" />}</span>
              <span className="bb">Airport Shuttle</span>
            </span>
            <span className="features">
              <span className="aa">{<MdDone className="icon" />}</span>
              <span className="bb">Free Parking</span>
            </span>
            <span className="features">
              <span className="aa">{<MdDone className="icon" />}</span>
              <span className="bb">Room Service</span>
            </span>
            <span className="features">
              <span className="aa">{<MdDone className="icon" />}</span>
              <span className="bb">Free WiFi</span>
            </span>
            <span className="features">
              <span className="aa">{<MdDone className="icon" />}</span>
              <span className="bb">Family Rooms</span>
            </span>
            
            <span className="features">
              <span className="aa">{<MdDone className="icon" />}</span>
              <span className="bb">Breakfast</span>
            </span>
            <span className="features">
              <span className="aa">{<MdDone className="icon" />}</span>
              <span className="bb">24-hour Front Desk</span>
            </span>
            <span className="features">
              <span className="aa">{<MdDone className="icon" />}</span>
              <span className="bb">Garden</span>
            </span>
            <span className="features">
              <span className="aa">{<MdDone className="icon" />}</span>
              <span className="bb">Air Conditioning</span>
            </span>
          </div>
        </div>

            <div className="facilities-categories">
              <div className="category">
                <h3>Great for your stay</h3>
                <ul>
                  <li><MdAirportShuttle className="icon" /> Airport shuttle</li>
                  <li><MdLocalParking className="icon" /> Free parking</li>
                  <li><MdDone className="icon" /> Room service</li>
                  <li><MdWifi className="icon" /> Free WiFi</li>
                  <li><FaUtensils className="icon" /> Breakfast</li>
                </ul>
              </div>
              <div className="category">
                <h3>Great for your stay</h3>
                <ul>
                  <li><FaBath className="icon" /> Private bathroom</li>
                  <li><MdLocalParking className="icon" /> Parking</li>
                  <li><TbAirConditioning className="icon" /> Air Conditioning</li>
                  <li><MdWifi className="icon" /> Free WiFi</li>
                  <li><FaBed className="icon" /> Family rooms</li>
                </ul>
              </div>
              <div className="category">
                <h3>Outdoors</h3>
                <ul>
                  <li><FaTree className="icon" /> Garden</li>
                  <li><MdDone className="icon" /> BBQ facilities</li>
                  <li><MdDone className="icon" /> Terrace</li>
                </ul>
              </div>
              <div className="category">
                <h3>Safety & Security</h3>
                <ul>
                  <li><MdDone className="icon" /> Fire extinguishers</li>
                  <li><MdDone className="icon" /> CCTV in common areas</li>
                  <li><MdDone className="icon" /> CCTV outside property</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferDetail;
