import { Ri24HoursLine, RiHotelLine, RiParkingLine, RiWifiLine, RiRestaurantLine } from "react-icons/ri";
import { TbAirConditioning } from "react-icons/tb";

export const aboutData = [
  {
    title: "24*7 Service",
    description: "Enjoy round-the-clock service, ensuring your needs are met at any time of day.",
    icon: <Ri24HoursLine /> // Icon for 24/7 service
  },
  {
    title: "Bar",
    description: "Relax at our fully stocked bar, offering a variety of drinks in a cozy atmosphere",
    icon: <RiRestaurantLine /> // Icon for bar
  },
  {
    title: "Room service",
    description: "Experience comfort with our 24/7 room service, delivering meals and essentials.",
    icon: <RiHotelLine /> // Icon for room service
  },
  {
    title: "Free parking",
    description: "Complimentary secure parking is available for all hotel guests.",
    icon: <RiParkingLine /> // Icon for parking
  },
  {
    title: "Free WiFi",
    description: "Stay connected with high-speed WiFi, available throughout the property.",
    icon: <RiWifiLine /> // Icon for WiFi
  },
  {
    title: "Air conditioning",
    description: "All rooms are equipped with air conditioning for a comfortable stay.",
    icon: <TbAirConditioning  /> // Icon for AC
  }
];
