import React, { useEffect } from "react";
import { aboutData } from "./data"; // Adjust the path as necessary
import './About.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';

const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, 
      easing: 'ease-in-out',
      once: false,
    });
  }, []);
  return (
    <div className="about section" >
      <div className="secContainer">
        <h1 className="title" data-aos="zoom-in">Why Heaven Palace??</h1>

        <div className="mainContent container grid">
          {aboutData.map((item, index) => (
            <div className="singleItem" key={index} data-aos="zoom-in">
              <div className="icon">{item.icon}</div> {/* Display the icon */}
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
