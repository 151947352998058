// src/Components/Home/Home.jsx
import React from "react";
import HomeComp from "../Components/Home/HomeComp";
import Popular from "../Components/Popular/Popular";
import Offer from "../Components/Offer/Offer";
import About from "../Components/About/About";
import Gallery from "../Components/Gallery/WSPGallery";
import Navbar from '../Components/Navbar/Navbar';

const Home = () => {
  return (
    <div>
      <section>
      <Navbar />
      </section>
      <section id="home">
        <HomeComp />
      </section>
      <section id="popular">
        <Popular />
      </section>
      <section id="offer">
        <Offer />
      </section>
      <section id="about">
        <About />
      </section>
      <section id="gallery">
        <Gallery />
      </section>
    </div>
  );
};

export default Home;
