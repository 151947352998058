import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home';
import Footer from './Components/Footer/Footer';
import OfferDetail from './Components/Offer/offerDetail';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/offer/:id" element={<OfferDetail />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
