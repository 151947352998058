import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Offer.scss";
import { MdBathtub, MdAirportShuttle, MdDone } from "react-icons/md";
import { BsArrowRightShort } from "react-icons/bs";
import { FaWifi } from "react-icons/fa";
import { IoMdPricetags } from "react-icons/io";
import { FaBed } from "react-icons/fa6";
import { ImSpoonKnife } from "react-icons/im";
import { TbAirConditioning } from "react-icons/tb";
import { FaBath } from "react-icons/fa";

import img5 from "../../Assets/img14.jpg";
import img6 from "../../Assets/img15.jpg";
import img7 from "../../Assets/img17.jpg";
import img8 from "../../Assets/img21.jpg";
import img9 from "../../Assets/img18.jpg";

import AOS from "aos";
import "aos/dist/aos.css";

const iconMapping = {
  "1 Bed": <FaBed className="icon" />,
  Beds: <FaBed className="icon" />,
  "Private Kitchen": <ImSpoonKnife className="icon" />,
  "Private Kitchens": <ImSpoonKnife className="icon" />,
  "Ensuite Bathroom": <MdBathtub className="icon" />,
  "Ensuite Bathrooms": <MdBathtub className="icon" />,
  "Air Conditioning": <TbAirConditioning className="icon" />,
  Shower: <MdBathtub className="icon" />,
  Bidet: <MdDone className="icon" />,
  Refrigerator: <MdDone className="icon" />,
  Fan: <MdDone className="icon" />,
  "Dining Area": <MdDone className="icon" />,
  "Dining Table": <MdDone className="icon" />,
  "Private Bathroom": <FaBath className="icon" />,
  "Free WiFi": <FaWifi className="icon" />,
};

export  const Offers = [
  {
    id: 1,
    imgSrc: img5,
    desTitle: "Standard Double Room",
    offer: "20%",
    grade: "LKR 13,441",
    option1: "1 Bed",
    option2: "240 m²",
    option3: "Private Kitchen",
    option4: "Private Bathroom",
    breakfast:"Breakfast LKR 730 (optional)",
    credit:"No credit card needed",
    cancel:"Free cancellation",
    prepayment :"No prepayment needed",
  },
  {
    id: 2,
    imgSrc: img6,
    desTitle: "Deluxe Double Room with Bath",
    offer: "20%",
    grade: "LKR 17,921",
    option1: "1 Bed",
    option2: "Private Kitchen",
    option3: "Ensuite Bathroom",
    option4: "Air Conditioning",
    breakfast:"Breakfast LKR 730 (optional)",
    credit:"No credit card needed",
    cancel:"Free cancellation",
    prepayment :"No prepayment needed",
  },
  {
    id: 3,
    imgSrc: img7,
    desTitle: "Deluxe Queen Room",
    offer: "20%",
    grade: "LKR 23,894",
    option1: "1 Bed",
    option2: "Private Kitchen",
    option3: "Ensuite Bathroom",
    option4: "Air Conditioning",
    breakfast:"Breakfast LKR 730 (optional)",
    credit:"No credit card needed",
    cancel:"Free cancellation",
    prepayment :"No prepayment needed",
  },
  {
    id: 4,
    imgSrc: img8,
    desTitle: "Deluxe Double Room with Shower",
    offer: "20%",
    grade: "LKR 23,894",
    option1: "1 Bed",
    option2: "Private Kitchen",
    option3: "Ensuite Bathroom",
    option4: "Air Conditioning",
    breakfast:"Breakfast LKR 730 (optional)",
    credit:"No credit card needed",
    cancel:"Free cancellation",
    prepayment :"No prepayment needed",
  },
  {
    id: 5,
    imgSrc: img9,
    desTitle: "Complete Villa",
    offer: "20%",
    grade: "LKR 36,000",
    option1: "Beds",
    option2: "Private Kitchens",
    option3: "Ensuite Bathrooms",
    option4: "Free WiFi",
    breakfast:"Breakfast LKR 730 (optional)",
    credit:"No credit card needed",
    cancel:"Free cancellation",
    prepayment :"No prepayment needed",
  },
];

const Offer = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
    });
  }, []);
  return (
    <section className="offer container section" data-aos="zoom-in">
      <div className="secContainer">
        <div className="secIntro">
          <h1 className="secTitle">Special Offers</h1>
          <p>
            From historical cities to natural spectaculars, come see the best of
            the world!
          </p>
        </div>

        <div className="mainContent grid">
          {Offers.map(
            ({
              id,
              imgSrc,
              desTitle,
              offer,
              grade,
              option1,
              option2,
              option3,
              option4,
            }) => {
              return (
                <div key={id} className="singleOffer" data-aos="zoom-in">
                  <div className="destImage">
                    <img src={imgSrc} alt="Image Name" />
                    <span className="discount">{offer}</span>
                  </div>

                  <div className="offerBody">
                    <div className="price flex">
                      <h4>
                        <span className="cardId">{id}. </span>
                        <span className="firstWord">
                          {desTitle.split(" ")[0]}
                        </span>{" "}
                        {desTitle.slice(desTitle.indexOf(" ") + 1)}
                      </h4>
                    </div>

                    <div className="amenities flex">
                      {[option1, option2, option3, option4].map((option) => (
                        <div className="singleAmenity flex" key={option}>
                          {iconMapping[option]}
                          <small>{option}</small>
                        </div>
                      ))}
                    </div>

                    {/* <div className="location flex">
                      <IoMdPricetags className="icon" />
                      <small>{grade}</small>
                    </div> */}

                    <button className="btn flex">
                      <Link to={`/offer/${id}`}>
                        View Details
                        <BsArrowRightShort className="icon" />
                      </Link>
                    </button>
                  </div>
                </div>
              );
            }
          )}
        </div>

        <div className="contentend">
          <div className="firstRow" data-aos="zoom-in">
            More Features
          </div>
          <div className="secondRow" data-aos="zoom-in">
            <span className="features">
              <span className="aa">{<MdDone className="icon" />}</span>
              <span className="bb">Shower</span>
            </span>
            <span className="features">
              <span className="aa">{<MdDone className="icon" />}</span>
              <span className="bb">Bidet</span>
            </span>
            <span className="features">
              <span className="aa">{<MdDone className="icon" />}</span>
              <span className="bb">Kitchen</span>
            </span>
            <span className="features">
              <span className="aa">{<MdDone className="icon" />}</span>
              <span className="bb">Refrigerator</span>
            </span>
            <span className="features">
              <span className="aa">{<MdDone className="icon" />}</span>
              <span className="bb">Fan</span>
            </span>
            <span className="features">
              <span className="aa">{<MdDone className="icon" />}</span>
              <span className="bb">Dining Area</span>
            </span>
            <span className="features">
              <span className="aa">{<MdDone className="icon" />}</span>
              <span className="bb">Dining Table</span>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Offer;
