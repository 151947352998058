import React, { useEffect } from 'react';
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import Slider from "react-slick";
import './Popular.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

import img1 from '../../Assets/1.jpg';
import img2 from '../../Assets/2.jpg';
import img3 from '../../Assets/3.jpg';
import img4 from '../../Assets/4.jpg';
import img5 from '../../Assets/8.jpeg';
import img6 from '../../Assets/9.jpeg';
import img7 from '../../Assets/10.jpg';

const Data = [
    {
        id: 1,
        imgSrc: img1,
        desTitle: 'Dalada Maligawa',
        location: 'Kandy',
        grade: 'The Dalada Maligawa in Kandy houses the Sacred Tooth Relic, a revered Buddhist pilgrimage site.'
    },
    {
        id: 2,
        imgSrc: img2,
        desTitle: 'Royal Botanical Gardens',
        location: 'Kandy',
        grade: 'Royal Botanical Gardens Peradeniya features diverse plant species, beautiful landscapes, and serene walking paths.'
    },
    {
        id: 3,
        imgSrc: img3,
        desTitle: 'Kandy Lake',
        location: 'Kandy',
        grade: 'Kandy Lake, surrounded by lush hills, offers serene views and a peaceful atmosphere in Kandy.'
    },
    {
        id: 4,
        imgSrc: img5,
        desTitle: 'Pinnawala Elephant Orphanage',
        location: 'Pinnawala',
        grade: 'Pinnawala Elephant Orphanage cares for and rehabilitates rescued elephants in Sri Lanka.'
    },
    {
        id: 5,
        imgSrc: img4,
        desTitle: 'National Museum Kandy',
        location: 'Kandy',
        grade: 'The National Museum in Kandy showcases Sri Lanka\'s rich history, culture, and diverse heritage collections.'
    },
    {
        id: 6,
        imgSrc: img6,
        desTitle: 'Ceylon Tea Museum',
        location: 'Kandy',
        grade: 'The Ceylon Tea Museum showcases Sri Lanka\'s rich tea heritage, offering insights into tea production.'
    },
    {
        id: 7,
        imgSrc: img7,
        desTitle: 'Railway Museum',
        location: 'Kadugannawa',
        grade: 'The Railway Museum in Kadugannawa showcases Sri Lanka\'s railway history with vintage locomotives and exhibits.'
    },
    
];

const PrevArrow = ({ onClick }) => (
    <div className='custom-arrow left circle-arrow' onClick={onClick} data-aos="zoom-in">
        <BsArrowLeftShort className='icon' />
    </div>
);

const NextArrow = ({ onClick }) => (
    <div className='custom-arrow right circle-arrow' onClick={onClick} data-aos="zoom-in">
        <BsArrowRightShort className='icon' />
    </div>
);

const Popular = () => {
    useEffect(() => {
        AOS.init({
          duration: 1000, 
          easing: 'ease-in-out',
          once: false,
        });
    }, []);
    const settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true, 
        autoplaySpeed: 2500, 
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section className="popular section container">
            <div className="secContainer">
                <div className="secHeader flex">
                    <div className="textDiv" data-aos="zoom-in">
                        <h2 className="secTitle">Popular Destinations</h2>
                        <p>From historical cities to natural spectaculars, come see the best of the world!</p>
                    </div>
                </div>

                <Slider {...settings} className="mainContent grid">
                    {
                        Data.map(({ id, imgSrc, desTitle, location, grade }) => (
                            <div key={id} className="singleDestination" data-aos="zoom-in">
                                <div className="destImage">
                                    <img src={imgSrc} alt={desTitle} />
                                    <div className="overlayInfo">
                                        <h3>{desTitle}</h3>
                                        <p>{grade}</p>
                                        <BsArrowRightShort className='icon' />
                                    </div>
                                </div>

                                <div className="desFooter">
                                    <span className="number">{String(id).padStart(2, '0')}</span>
                                    <span className="desText flex">
                                        <h6>{desTitle}</h6>
                                        <span className="dot">{grade}</span>
                                    </span>
                                </div>
                            </div>
                        ))
                    }
                </Slider>
            </div>
        </section>
    );
}

export default Popular;
